/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {

	function is_mobile_device () {
		var isAndroid = /android/i.test(navigator.userAgent.toLowerCase());
		var isiDevice = /ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase());
		var isBlackBerry = /blackberry/i.test(navigator.userAgent.toLowerCase());
		var isWebOS = /webos/i.test(navigator.userAgent.toLowerCase());
		var isWindowsPhone = /windows phone/i.test(navigator.userAgent.toLowerCase());
		if (isAndroid || isiDevice || isBlackBerry || isWebOS || isWindowsPhone) {
			return (true);
		}
		else {
			return (false);
		}

	}

	var Sage = {
		// All pages
		'common': {
			init: function () {

				$(".startup-box").click(function () {
					if ($(window).width() <= 768) {
						var urlPage = $(this).find("a").attr("href");
						window.location = urlPage;
					}
				});

				if (is_mobile_device() === false) {
					$('.startup-box').mouseenter(function () {
						$(this).find('.backgroundOpacity').addClass('opacity-startup');
						$(this).find('.backgroundOpacity').find('h3').addClass('opacity');
						$(this).find('.content-box').removeClass('opacity');
						$(this).find('img').removeClass('filter-img');
					});
					$('.startup-box').mouseleave(function () {
						$(this).find('.backgroundOpacity').removeClass('opacity-startup');
						$(this).find('.backgroundOpacity').find('h3').removeClass('opacity');
						$(this).find('.content-box').addClass('opacity');
						$(this).find('img').addClass('filter-img');
					});
				}

				var option = {
					byRow: false,
					target: $('.startup-box').first()
				};

				$('.startup-box').matchHeight(option);
				$('.equal-height').matchHeight();

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired

				$(document).ready(function () {
					$('.partner').owlCarousel({
						nav: false,
						loop: true,
						margin: 30,
						autoWidth: true,
						autoplay: true,
						smartSpeed: 1000,
						autoplayTimeout: 3000,
						dots: false

					});
				});
			}
		},
		// Home page
		'home': {
			init: function () {


				$(".box").click(function () {
					if ($(window).width() <= 768) {
						var urlPage = $(this).find("a").attr("href");
						window.location = urlPage;
					}
				});

				if (is_mobile_device() === false
				) {
					$('.box').mouseenter(function () {
						$(this).children('h3').addClass('opacity');
						$(this).find('.content-box').removeClass('opacity');
					});
					$('.box').mouseleave(function () {
						$(this).children('h3').removeClass('opacity');
						$(this).find('.content-box').addClass('opacity');
					});
				}

			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		},
		'notre_philosophie': {
			init: function () {

			},
			finalize: function () {
				var team = $('.team-button');
				team.click(function () {
					if (team.attr('aria-expanded') !== 'true') {
						team.text('Masquer l\'équipe');
					} else {
						team.text('Découvrir l\'équipe');
					}
				});
			}
		},
		'partenaires_de_ceei_synergie': {
			init: function () {

			},
			finalize: function () {
				$('.listing-partner').appear(function () {
					$('img', this).each(function (i, el) {
						setTimeout(function () {
							$(el).removeClass('invisible').addClass('animated zoomIn');
						}, 500 + (i * (Math.floor(Math.random() * Math.floor(10) * 30))));
					});​
				});
			}
		}
	};


// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

// Load Events
	$(document).ready(UTIL.loadEvents);

})
(jQuery); // Fully reference jQuery after this point.
